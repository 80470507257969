import helpers from './helpers';
const OrbitControls = require('three-orbitcontrols');
import ARControls from './arControls';

class VolcapPlayer extends HTMLElement {
    
    static get observedAttributes() {
        let attributes = [
            "floorimage", "eqimage", "backgroundcolor", "hideallcontrols", "hidecontrols", "arKey"
        ]
        return attributes;
    }

    constructor() {
        super();

        this._width = 800;
        this._height = 1200;

        this._canvas = null;

        // three js
        this._renderer = null;
        this._camera = null;
        this._scene = null;
        this._clock = null;
        this._cameraControls = null;
        this._textureLoader = null;// observe all attributes to see if they change
        this._bounds = null;

        // eighti
        this._onLog = null;

        this._player = null;
        this._viewport = null;

        this._actorElement = null;
        this._fullscreenElement = null;

        this._previousTime = 0.0;

        this._activePose = null;
        this._hidden = false;

        this._isFullscreen = false;
        this._isLooping = false;
        this._resizeRequired = false;
        this._autoplay = false;
        this._muted = false;
        this._onPlay = null;
        this._onEvent = null;
        this._floorImage = 'floor_default.jpg';
        this._floor = null;
        this._backgroundColor = 0x000000;
        this._transparentBackground = false;
        this._backgroundIMG = null
        this._eqImgAddress = null;
        this._hideAllControls = false;
        this._hideControls = [];

        // AR flags
        this._startupAR = false;
        this._arEnabled = false;
        this._arSupported = false;
        this._arButton = null;
        this._vrButton = null;

        this._shadowRoot = this.attachShadow({mode: 'open'});

        let fontCSS = document.createElement('style');
        fontCSS.type = 'text/css';
        fontCSS.rel = 'stylesheet';
        fontCSS.textContent = `
        @font-face {
          font-family: 'Simple-Line-Icons';
          src:url('https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/fonts/Simple-Line-Icons.eot');
          src:url('https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/fonts/Simple-Line-Icons.eot?#iefix') format('embedded-opentype'),
            url('https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/fonts/Simple-Line-Icons.woff') format('woff'),
            url('https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/fonts/Simple-Line-Icons.ttf') format('truetype'),
            url('https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/fonts/Simple-Line-Icons.svg#Simple-Line-Icons') format('svg');
          font-weight: normal;
          font-style: normal;
        }`;
        document.getElementsByTagName('head')[0].appendChild(fontCSS);
    }
    mutations (mutationsList, observer) {
        // Use traditional 'for loops' for IE 11
        for(let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                mutation.removedNodes
                    .forEach((node) => {
                        if (node.localName === 'volcap-actor') {
                            this._actorElement = null;
                        }
                    })
                mutation.addedNodes
                    .forEach((node) => {
                        if (node.localName === 'volcap-actor') {
                            this._actorElement = node;
                            this._actorElement.onplay = this._onPlayInternal.bind(this);
                            this._actorElement.onpause = this._onPause.bind(this);
                            if (this._scene && this._renderer) {
                                this._startLoadingAnimation();
                                this._actorElement.onloadeddata = () => {
                                    this._stopLoadingAnimation();
                                };
                                this._actorElement.initialise(this._scene, this._renderer, {fps: this._fps, muted: this._muted, autoplay: this._autoplay, loop: this._isLooping});
                                this._arControls.attach(this._actorElement._impl._mesh);
                                if (this._arSupported) {
                                    if (this._actorElement.arEnabled) {
                                        this._arButton.classList.remove('hide');
                                        this._vrButton.classList.add('hide');
                                    } else {
                                        this._arButton.classList.add('hide');
                                        this._vrButton.classList.add('hide');
                                    }
                                }
                            }
                        }
                    });
            }
            else if (mutation.type === 'childList' && mutation.localName === 'volcap-actor') {
                console.log('source removed');
            }
        }
    };

    connectedCallback() {
        if(this.hasAttribute('onlog')) {
            this._onLog = this.getAttribute('onlog');
        }
        if(this.hasAttribute('autoplay')) {
            this._autoplay = this.getAttribute('autoplay') == 'true';
        }
        if(this.hasAttribute('autoloop')) {
            this._isLooping = this.getAttribute('autoloop') == 'true';
        }
        if(this.hasAttribute('onPlay')) {
            this._onPlay = this.getAttribute('onPlay');
            console.warn('"onPlay" is now depricated, please use "onEvent"');
        }
        if(this.hasAttribute('onEvent')) {
            let funcName = this.getAttribute('onEvent');
            try {
                this._onEvent = Function("event", "params", "return " + funcName + "(event, params)");
            } catch(e) {
                if(e instanceof SyntaxError) {
                    console.error('"onEvent" callback syntax error');
                } else {
                    throw e;
                }
            }
        }
        if(this.hasAttribute('transparent')) {
            this._transparentBackground = this.getAttribute('transparent').toLowerCase() == 'true';
        }
        if(this.hasAttribute('arKey')) {
            this._arKey = this.getAttribute('arKey');
        }

        if(this.hasAttribute('startupAR')) {
            this._startupAR = this.getAttribute('startupAR') == 'true';
        }

        // Watch for changes to this node's children.
        const observer = new MutationObserver(this.mutations.bind(this));
        observer.observe(this, { childList: true });

        this._createDom();
    }

    attributeChangedCallback(name, oldValue, newValue){
        newValue = newValue.toLowerCase();
        var oValue = null;
        var nValue = newValue;
        if(!oldValue){
            oValue = "None";
        }
        if(newValue == ""){
            nValue = "emptyString";
        }
        console.log("Attribute Changed:", name, oValue, nValue);
        if(name == 'floorimage'){
            //oldvalue doesnt exist unless initilaized, prevents error being thrown
            this._floorImage = newValue;
            this._updateFloor();
        }
        if(name == 'backgroundcolor'){
            this._backgroundColor = newValue;
            // IF there is an image remove it to show a colored background instead unless the new color is no color
            this._eqImgAddress = "";
            this._updateEQIMGBackground();
            
            this._updateBackgroundColor();
        }
        if(name == 'eqimage' ){
            this._eqImgAddress = newValue;
            this._updateEQIMGBackground();
        }
        if(name == "hideallcontrols"){
            this._hideAllControls = newValue == 'true';
            this._updateControlVisibility();
        }
        if(name == "hidecontrols"){
            this._hideControls = newValue.split(',');
            this._updateControlVisibility();
        }
        if (name == "arKey"){
            this._arKey = newValue;
        }
    }

    _restart() {
        let paused = this._actorElement.paused;
        this._actorElement.destroy();
        this._actorElement.initialise(this._scene, this._renderer, {fps: this._fps, muted: this._muted, autoplay: !paused, loop: this._isLooping});
        this._arControls.attach(this._actorElement._impl._mesh);
    }

    _onARSessionEnd() {
        this._arButton.classList.remove('hide');
        this._vrButton.classList.add('hide');
        this._arSession.removeEventListener('end', this._onARSessionEnd.bind(this));
        this._renderer.xr.setSession(null);
        this._xrHitTestSource = null;
        this._xrRefSpace = null;
        this._xrViewerSpace = null;
        this._latestPosition = null;
        this._reticle.visible = false;
        this._shadowFloor.visible = false;
        this._hidden = false;
        this._updateBackgroundColor();
        this._arSession = null;
        this._arControls.enabled = false;
        this._cameraControls.enabled = true;
    }

    _EighthWallPipelineModule() {
        return {
            // Pipeline modules need a name. It can be whatever you want but must be unique within your app.
            name: '8i',
            // onStart is called once when the camera feed begins. In this case, we need to wait for the
            // XR8.Threejs scene to be ready before we can access it to add content. It was created in
            // XR8.Threejs.pipelineModule()'s onStart method.
            onStart: ({canvas, canvasWidth, canvasHeight, GLctx}) => {
                // Sync the xr controller's 6DoF position and camera paremeters with our scene.
                XR8.XrController.updateCameraProjectionMatrix({
                    origin: this._camera.position,
                    facing: this._camera.quaternion,
                });

            },
            onUpdate: ({processCpuResult}) => {
                let c = processCpuResult.reality || processCpuResult.facecontroller;
                if (c) {
                    for (let i = 0; i < 16; i++)
                        this._camera.projectionMatrix.elements[i] = c.intrinsics[i];
                    this._camera.projectionMatrixInverse.getInverse(this._camera.projectionMatrix);
                    this._camera.setRotationFromQuaternion(c.rotation);
                    this._camera.position.set(c.position.x, c.position.y, c.position.z);
                    this._camera.updateMatrixWorld();
                    this._camera.matrixWorldInverse.getInverse(this._camera.matrixWorld);
                }
                this._renderer.clear(true, true, true);
            },
            onRender: () => {
                if (this._floor)
                    this._floor.visible = false;
                if (this._shadowFloor)
                    this._shadowFloor.visible = true;
                this._render();
            },
        }
    }

    _stop8thWallAR() {
        XR8.stop();
        XR8.clearCameraPipelineModules();
        this._arEnabled = false;
        this._arControls.enabled = false;
        this._arButton.classList.remove('hide');
        this._vrButton.classList.add('hide');
        this._renderer.setAnimationLoop(this._animate.bind(this));
    }

    _start8thWallAR() {
        this._arEnabled = true;
        this._arControls.enabled = true;
        this._vrButton.classList.remove('hide');
        this._arButton.classList.add('hide');
        this._renderer.setAnimationLoop(null);
        XR8.addCameraPipelineModules([
            XR8.GlTextureRenderer.pipelineModule(),      // Draws the camera feed.
            XR8.XrController.pipelineModule(),           // Enables SLAM tracking.
            this._EighthWallPipelineModule(),
        ]);
        XR8.run({canvas: this._canvas});
    }

    _on8thWallLoaded() {
        this._arSupported = true;
        this._arButton = this._shadowRoot.querySelector('.ar');
        this._arButton.onclick = () => {
            this._start8thWallAR();
        }
        this._vrButton = this._shadowRoot.querySelector('.vr');
        this._vrButton.onclick = () => {
            this._stop8thWallAR();
        }
        this._arButton.classList.remove('hide');
        this._vrButton.classList.add('hide');
        this._initNativeRenderer();
        if (this._startupAR) {
            this._start8thWallAR();
        }
    }

    _detectARSupport() {
        if (this._arKey && helpers.userAgentIs.mobile()) {
            // Lazy-load 8thWall AR
            let arScript = document.createElement('script');
            arScript.src = `//apps.8thwall.com/xrweb?appKey=${this._arKey}`;
            window.addEventListener('xrloaded', this._on8thWallLoaded.bind(this));
            document.body.appendChild(arScript);
        } else {
            this._arButton = this._shadowRoot.querySelector('.ar');
            this._vrButton = this._shadowRoot.querySelector('.vr');
            this._vrButton.onclick = () => {
                if (this._arSession) {
                    this._arSession.end();
                }
            }
            this._arSession = null;
            // Feature policy check is required to prevent Chrome iframe breakage!
            if (navigator.xr && (!document.featurePolicy || document.featurePolicy.allowsFeature('xr-spatial-tracking'))) {
                navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
                    if (supported) {
                        this._arSupported = true;
                        document.addEventListener('beforexrselect', (ev) => {
                            console.log('blocking XR controls');
                            ev.preventDefault();
                        });
                        this._arButton.onclick = () => {
                            if (this._arSession === null) {
                                let overlay = this._shadowRoot.querySelector('.eighti-container');
                                navigator.xr.requestSession('immersive-ar', {
                                    requiredFeatures: ['local', 'hit-test'],
                                    optionalFeatures: ['dom-overlay', 'dom-overlay-for-handheld-ar'],
                                    domOverlay: {
                                        root: overlay,
                                    }
                                }).then((session) => {
                                    this._vrButton.classList.remove('hide');
                                    this._arButton.classList.add('hide');
                                    session.addEventListener('end', this._onARSessionEnd.bind(this));
                                    this._renderer.xr.setReferenceSpaceType('local');
                                    this._renderer.xr.setSession(session);
                                    this._arSession = session;
                                    this._hidden = true; // hide 'actor' while initializing AR
                                    this._cameraControls.enabled = false;
                                    this._arControls.enabled = true;
                                    session.requestReferenceSpace('viewer').then((refSpace) => {
                                        this._xrViewerSpace = refSpace;
                                        session.requestHitTestSource({ space: this._xrViewerSpace })
                                        .then((hitTestSource) => {
                                            this._renderer.setClearColor(this._backgroundColor, 0);
                                            this._xrHitTestSource = hitTestSource;
                                        });
                                    });
                                    session.requestReferenceSpace('local').then((refSpace) => {
                                        this._xrRefSpace = refSpace;
                                    });
                                })
                            } else {
                                this._arSession.end();
                            }
                        }
                        this._arButton.classList.remove('hide');
                    }
                })
                .catch(e => console.error('WebXR not supported', e))
                .finally(() => this._initNativeRenderer());
            } else {
                this._initNativeRenderer();
            }
        }
    }

    _createDom() {
        const css = require('!raw-loader!../eighti-player/volcap-player.css').default;
        const html = require('to-string-loader!html-loader!../eighti-player/volcap-player.html').toString();

        let style = document.createElement('style');
        style.type = 'text/css';
        style.rel = 'stylesheet';
        style.textContent = css;
        this._shadowRoot.appendChild(style);
        this._shadowRoot.innerHTML += html;

        this._canvas = this._shadowRoot.querySelector('#canvas');

        this._progressBar = this._shadowRoot.querySelector('.progress');
        this._progressBar.onclick = this._seek.bind(this);

        this._progressDownload = this._shadowRoot.querySelector('.download');
        this._progressPlayed = this._shadowRoot.querySelector('.played');

        this._playButton = this._shadowRoot.querySelector('.control-play');
        this._playButton.onclick = this._play.bind(this);
        this._pauseButton = this._shadowRoot.querySelector('.control-pause');
        if (this._autoplay) {
          this._muted = true; // Must mute due to AudioContext autoplay restrictions
          this._playButton.classList.add('hide');
        } else {
            this._pauseButton.classList.add('hide');
        }
        this._pauseButton.onclick = this._pause.bind(this);

        this._loopButton = this._shadowRoot.querySelector('.control-loop');
        if (this._isLooping) {
            this._loopButton.classList.remove('inactive');
        } else {
            this._loopButton.classList.add('inactive');
        }
        this._loopButton.onclick = this._toggleLooping.bind(this);

        this._volumeUnmutedButton = this._shadowRoot.querySelector('.volume-unmuted');
        this._volumeUnmutedButton.onclick = this._mute.bind(this);
        this._volumeMutedButton = this._shadowRoot.querySelector('.volume-muted');
        this._volumeMutedButton.onclick = this._unmute.bind(this);
        if (this._muted) {
          this._volumeUnmutedButton.classList.add('hide');
        } else {
          this._volumeMutedButton.classList.add('hide');
        }

        this._fullscreenButton = this._shadowRoot.querySelector('.size-fullscreen');
        this._fullscreenButton.onclick = this._goFullscreen.bind(this, true);
        this._actualButton = this._shadowRoot.querySelector('.size-actual');
        this._actualButton.classList.add('hide');
        this._actualButton.onclick = this._goFullscreen.bind(this, false);

        // Listen for FPS change events
        this._fps = 15;
        let fps = this._shadowRoot.querySelector('.control-fps');
        if (fps) {
            fps.classList.remove('hide');
            fps.addEventListener('click', (evt) => {
                console.log(evt);
                if (fps.classList.contains('inactive')) {
                    console.log('enable super speed');
                    fps.classList.remove('inactive');
                    this._fps = 30;
                    this._restart();
                } else {
                    console.log('disable super speed');
                    fps.classList.add('inactive');
                    this._fps = 15;
                    this._restart();
                }
            })
        }

        this._controlsContainer = this._shadowRoot.querySelector('.collapsing');
        // Block XR touch events through controls
        this._controlsContainer.addEventListener('beforexrselect', (ev) => {
            console.log('blocking XR controls');
            ev.preventDefault();
        });

        this._updateControlVisibility();

        this._setUpFullscreen();

        if (!this._canvas) {
            console.error('no canvas found')
            return
        }
        if (this._canvas.clientWidth == 0) {
            console.log('canvas was sized at', this._width, this._height);
            console.log('canvas resizing to', this._canvas.clientWidth, this._canvas.clientHeight);
            console.error('volcap-player should not be resized to 0 width');
            return
        }
        this._width = this._canvas.clientWidth;
        this._height = this._canvas.clientHeight;

        this._canvas.width = this._width;
        this._canvas.height = this._height;

        if(!this._camera) {
            this._camera = new THREE.PerspectiveCamera( 70, this._width / this._height, 0.01, 50 );
            this._camera.position.set(0,1.25,1.75);
        }

        if(!this._scene) {
            this._scene = new THREE.Scene();
        }

        this._reticle = new THREE.Mesh(
            new THREE.RingBufferGeometry( 0.15, 0.2, 32 ).rotateX( - Math.PI / 2 ),
            new THREE.MeshBasicMaterial()
        );
        this._reticle.matrixAutoUpdate = false;
        this._reticle.visible = false;
        this._scene.add( this._reticle );

        let light1 = new THREE.SpotLight( 0xffffff );
        light1.position.set( 3, 10, 5 );
        this._scene.add( light1 );
        light1.shadow.mapSize.width = 2048;
        light1.shadow.mapSize.height = 2048;
        light1.shadow.camera.near = 0.5;
        light1.shadow.camera.far = 500;
        light1.shadow.focus = 5;

        // Hack for softening shadows in THREE.
        // https://github.com/mrdoob/three.js/pull/14087
        var shadowIntensity = 0.5; // between 0 and 1

        var light2 = light1.clone();
        this._scene.add( light2 );
        light1.castShadow = true;
        light2.castShadow = false;
        light1.intensity = shadowIntensity;
        light2.intensity = 1 - shadowIntensity;

        // Depending on the outcome of this, we use either our native renderer
        // or the 8thWall AR renderer.
        this._detectARSupport();
    }

    _initNativeRenderer() {
        this._initScene();
        this._renderer.setAnimationLoop(this._animate.bind(this));
    }

    _initScene(context) {
        let material = new THREE.ShadowMaterial({opacity: 0.5});
        let plane = new THREE.PlaneBufferGeometry(1, 1);
        let shadowFloor = new THREE.Mesh(plane, material);
        shadowFloor.rotation.x = Math.PI * 1.5;
        shadowFloor.scale.set(10, 10, 10);
        shadowFloor.receiveShadow = true;
        shadowFloor.visible = false;
        this._shadowFloor = shadowFloor;
        this._scene.add(shadowFloor);

        if(!this._renderer) {
            this._renderer = new THREE.WebGLRenderer( { antialias: false, canvas: this._canvas, alpha: true, context } );
            this._renderer.autoClear = false;
            // this is needed for GPU vendor detection
            this._renderer.getContext().getExtension('WEBGL_debug_renderer_info');
            if (this._renderer.getPixelRatio() != window.devicePixelRatio) {
                // Only set pixel ratio if its not already set.
                // iPhones sometimes do crazy things if you set this more than once per screen orientation?
                // I'm not really sure what their deal is entirely, but this seems to fix it.
                this._renderer.setPixelRatio(window.devicePixelRatio);
            }
            this._renderer.setSize( this._width, this._height, false);

            this._renderer.xr.enabled = true;

            this._arControls = new ARControls(this._renderer.domElement);
            this._renderer.domElement.addEventListener( 'select', this.onSelect.bind(this) );

            this._latestPosition = null;

            this._renderer.shadowMap.enabled = true;
            this._renderer.shadowMap.type = THREE.PCFSoftShadowMap;

            // Default to black background.
            this._updateBackgroundColor();
            this._updateEQIMGBackground();
        }

        this._updateFloor();

        if(!this._clock) {
            this._clock = new THREE.Clock(true);
        }

        if(!this._cameraControls)
        {
            this._cameraControls = new OrbitControls( this._camera, this._renderer.domElement );
            this._cameraControls.target.set(0, 1, 0);
            this._cameraControls.minDistance = 0.5;
            this._cameraControls.maxDistance = 10
            this._cameraControls.update();
        }

        if (this._actorElement) {
            this._actorElement.initialise(this._scene, this._renderer, {fps: this._fps, muted: this._muted, autoplay: this._autoplay, loop: this._isLooping});
            this._actorElement.onloadeddata = () => {
                this._stopLoadingAnimation();
            };
            // TODO don't access the nested private members like this...
            this._arControls.attach(this._actorElement._impl._mesh);
            if (this._arSupported) {
                if (this._actorElement.arEnabled) {
                    this._arButton.classList.remove('hide');
                    this._vrButton.classList.add('hide');
                } else {
                    this._arButton.classList.add('hide');
                    this._vrButton.classList.add('hide');
                }
            }
        }
    }

    onSelect() {
        if ( this._reticle.visible ) {
            this._latestPosition = new THREE.Vector3().fromArray(this._reticle.matrix.elements, 12);
        }
        if (XR8) {
            XR8.XrController.recenter();
        }
    }

    _updateControlVisibility(){
        if(!this._controlsContainer)
            return;

        if (this._hideAllControls) {
            this._controlsContainer.classList.add('hide');
            if (helpers.userAgentIs.notMobile()) {
                let container = this._shadowRoot.querySelector('.eighti-container');
                container.onmousemove = null;
                container.onclick = null;
            }
        } else {
            this._controlsContainer.classList.remove('hide');
            // Process hidecontrols list
            let supportedControls = [
                'progress',
                'playpause',
                'fullscreen',
                'volume',
                'arvr'
            ];
            for (let control of supportedControls) {
                if (this._hideControls.includes(control)) {
                    this._controlsContainer.querySelector(`.${control}`).classList.add('hide');
                } else {
                    this._controlsContainer.querySelector(`.${control}`).classList.remove('hide');
                }
            }

            if (false && helpers.userAgentIs.notMobile()) {
                this._controlsContainerTimeout = null;
                let container = this._shadowRoot.querySelector('.eighti-container');
                function updateControls() {
                    this._controlsContainer.classList.remove('hide');
                    if(this._controlsContainerTimeout) {
                        clearTimeout(this._controlsContainerTimeout);
                    }
                    this._controlsContainerTimeout = setTimeout(function() {
                        this._controlsContainer.classList.add('hide');
                        this._controlsContainerTimeout = null;
                    }.bind(this), 4000);
                }
                container.onmousemove = updateControls.bind(this);
                container.onclick = updateControls.bind(this);
                this._controlsContainerTimeout = setTimeout(function() {
                    this._controlsContainer.classList.add('hide');
                    this._controlsContainerTimeout = null;
                }.bind(this), 10000);
            }// ELSE Keep the controlsContainer showing at all times
        } 
    }

    _play(startTime=null) {

        if(isNaN(startTime)) {
            startTime = null;
        }

        this._actorElement.play();

        let duration = -1.0;
        let currentTime = -1.0;

        if(startTime == null && !this._isLooping && this._actorElement) {
            duration = this._actorElement.duration;
            currentTime = this._actorElement.currentTime;
            if(currentTime >= duration) {
                startTime = 0.0
            }
        }
        if(startTime != null) {
            this._actorElement.seek(startTime);
        }
    }

    _onPlayInternal() {
        this._playButton.classList.add('hide');
        this._pauseButton.classList.remove('hide');
        eval(this._onPlay);
        this._triggerEvent('play', null);
    }

    _onPause() {
        this._pauseButton.classList.add('hide');
        this._playButton.classList.remove('hide');
        this._triggerEvent('pause', null);
    }

    _pause() {
        this._actorElement.pause();
    }

    _toggleLooping() {
        this._isLooping = !this._isLooping;
        if(this._isLooping) {
            this._loopButton.classList.remove('inactive');
            this._triggerEvent('loop', true);
        } else {
            this._loopButton.classList.add('inactive');
            this._triggerEvent('loop', false);
        }
        this._updateLooping();
    }

    _startLoadingAnimation() {
        this._progressDownload.style.display = "block";
    }

    _stopLoadingAnimation() {
        this._progressDownload.style.display = "none";
    }

    _updateLooping() {
        if(this._actorElement) {
            this._actorElement.loop = this._isLooping;
        }
    }

    _mute() {
        this._muted = true;
        this._volumeMutedButton.classList.remove('hide');
        this._volumeUnmutedButton.classList.add('hide');
        this._actorElement.muted = this._muted;
        this._triggerEvent('muted', true);
    }

    _unmute() {
        this._muted = false;
        this._volumeMutedButton.classList.add('hide');
        this._volumeUnmutedButton.classList.remove('hide');
        this._actorElement.muted = this._muted;
        this._triggerEvent('muted', false);
    }

    _goFullscreen(goFullscreen) {
        if(goFullscreen) {
            this._fullscreenButton.classList.add('hide');
            this._actualButton.classList.remove('hide');
            this._enterFullscreen();
            this._triggerEvent('fullscreen', true);
        } else {
            this._actualButton.classList.add('hide');
            this._fullscreenButton.classList.remove('hide');
            this._exitFullscreen();
            this._triggerEvent('fullscreen', false);
        }
    }

    _seek(event) {
        let percent = 0.0
        // if click was right at the beginning just seek to start
        if(event.offsetX > 5) {
            percent = event.offsetX / this._progressBar.clientWidth;
            percent = percent > 1.0 ? 1.0 : percent < 0.0 ? 0.0 : percent;
        }
        if(this._actorElement) {
            const duration = this._actorElement.duration;
            const seekTime = percent * duration;
            this._actorElement.seek(seekTime);
        }
        this._triggerEvent('seek', {'percent': percent});
    }

    _resize() {
        if(!this._resizeRequired && this._canvas.clientWidth == this._width && this._canvas.clientHeight == this._height) {
            return;
        }
        if (this._canvas.clientWidth == 0) {
            console.log('canvas was sized at', this._width, this._height);
            console.log('canvas resizing to', this._canvas.clientWidth, this._canvas.clientHeight);
            console.error('volcap-player should not be resized to 0 width');
        }
        this._width = this._canvas.clientWidth;
        this._height = this._canvas.clientHeight;

        this._canvas.width = this._width;
        this._canvas.height = this._height;

        if(this._camera) {
            this._camera.aspect = this._width / this._height;
            this._camera.updateProjectionMatrix();
        }

        if(this._renderer && !this._renderer.xr.isPresenting) {
            if (this._renderer.getPixelRatio() != window.devicePixelRatio) {
                // Only set pixel ratio if its not already set.
                // iPhones sometimes do crazy things if you set this more than once per screen orientation?
                // I'm not really sure what their deal is entirely, but this seems to fix it.
                this._renderer.setPixelRatio(window.devicePixelRatio);
            }
            this._renderer.setSize( this._width, this._height, false );
        }

        this._resizeRequired = false;
    }

    _triggerEvent(event, params) {
        if(this._onEvent) {
            this._onEvent(event, params);
        }
        this.dispatchEvent(new Event(event));
    }

    _updateEQIMGBackground(){
        if(!this._scene)
            return;

        if(this._backgroundIMG || !this._eqImgAddress){//clear scene of equirectangular image
            this._scene.remove(this._backgroundIMG);
            if(this._eqImgAddress){
                this._updateBackgroundColor();
            }
        }

        if(this._eqImgAddress){//add equirectangular image to geometry
            var geometry = new THREE.SphereGeometry( 15, 60, 40 );
            geometry.scale( - 1, 1, 1 );

            if(!this._textureLoader) {
                this._textureLoader = new THREE.TextureLoader();
            }

            var material = new THREE.MeshBasicMaterial( {
                map: this._textureLoader.load( this._eqImgAddress    )
            } );

            this._backgroundIMG = new THREE.Mesh( geometry, material );
            this._scene.add( this._backgroundIMG );
        }
    }

    _updateFloor(){
        if(!this._scene)
            return;

        if(this._floorImage){
            if(!this._textureLoader) {
                this._textureLoader = new THREE.TextureLoader();
            }
            this._textureLoader.load(
                this._floorImage,
                function(texture) {
                    texture.minFilter = THREE.NearestFilter;
                    texture.magFilter = THREE.NearestFilter;

                    var material = new THREE.MeshStandardMaterial({
                            map: texture
                    });
                    var plane = new THREE.PlaneBufferGeometry(1, 1);
                    var floor = new THREE.Mesh(plane, material);
                    floor.rotation.x = Math.PI * 1.5;
                    floor.scale.set(10, 10, 10);
                     //if old floor remove old floor
                    if(this._floor){
                        this._scene.remove(this._floor)
                    }
                    floor.receiveShadow = true;
                    this._floor = floor;
                    this._scene.add(floor);
                }.bind(this),
                function() {},
                function(error) {
                    console.error("Error rendering Floor:")
                    console.error(error);
                }
            );
        }
        else{
            this._scene.remove(this._floor)
        }
    }

    _updateBackgroundColor(){
        //If WebGL Alpha is true, then the scene background is transparent
        if(!this._renderer){
           return 
        }

        this._renderer.setClearColor(this._backgroundColor, this._transparentBackground ? 0 : 1);
    }

    _onCameraChanged(position, rotation) {
        this._triggerEvent('camera', {
            'position': { 'x': position.x, 'y': position.y, 'z': position.z },
            'rotation': { 'x': rotation.x, 'y': rotation.y, 'z': rotation.z, 'w': rotation.w }
        });
    }

    _animate(timestamp, frame) {
        this._resize();

        if (this._floor)
            this._floor.visible = !Boolean(frame);

        this._renderer.clear(true, true, true);

        this._render(timestamp, frame);
    }


    _render(timetamp, frame) {

        if (frame) {
            let session = frame.session;
            let xrViewerPose = frame.getViewerPose(this._xrRefSpace);
            if ( this._xrHitTestSource && xrViewerPose ) {
                let hitTestResults = frame.getHitTestResults( this._xrHitTestSource );
                if ( hitTestResults.length ) {
                    var hit = hitTestResults[ 0 ];
                    if (!this._reticle.visible) {
                    }
                    this._reticle.visible = true;
                    this._hidden = false;
                    this._shadowFloor.visible = true;
                    this._activePose = hit.getPose( this._xrRefSpace ).transform.matrix;
                    this._reticle.matrix.fromArray( this._activePose );
                } else {
                    if (this._reticle.visible) {
                    }
                    this._reticle.visible = false;
                    this._activePose = null;
                }
            }
        }
        
        // Wait actor has been initialised before using
        if(this._actorElement && this._actorElement.ready) {
            const duration = this._actorElement.duration;

            const elapsedTime = this._clock.getElapsedTime();
            const currentTime = this._actorElement.currentTime;

            this._previousTime = currentTime;

            this._actorElement.update(elapsedTime, currentTime, duration);

            // This is CRITICAL for WebXR mode - we need to re-bind the WebXR
            // framebuffer or nothing will be rendered to the XR display.
            const gl = this._renderer.getContext();
            let origFB = frame ? frame.session.renderState.baseLayer.framebuffer : null;
            gl.bindFramebuffer(gl.FRAMEBUFFER, origFB);

            let percent = 0;
            if(duration > 0.0) {
                percent = currentTime / duration;
                percent = percent > 1.0 ? 1.0 : percent < 0.0 ? 0.0 : percent
            }
            this._progressPlayed.style.width = (percent * 100) + '%';

            // actor position can be fixed or floating
            let position = new THREE.Vector3(0, 0, 0);
            if (this._latestPosition) {
                position = this._latestPosition;
            } else if (this._reticle.visible) {
                position.fromArray(this._reticle.matrix.elements, 12);
            }
            this._shadowFloor.position.copy(position);

            this._cameraControls.update();

            this._actorElement.render(this._width, this._height, this._camera, position, this._hidden);

            // Reset any state set by us back to what THREE.js requires
            this._renderer.state.reset();
        }

        // Render THREE.js scene
        this._renderer.render( this._scene, this._camera );
    }

    _setUpFullscreen() {
        this._fullscreenElement = this._shadowRoot.querySelector('.eighti-container');

        this._fullscreenElement.requestFullScreen = this._fullscreenElement['requestFullScreen'] ||
            this._fullscreenElement['mozRequestFullScreen'] ||
            this._fullscreenElement['msRequestFullscreen'] ||
            (this._fullscreenElement['webkitRequestFullScreen'] ? function () {
                this._fullscreenElement['webkitRequestFullScreen'](Element['ALLOW_KEYBOARD_INPUT'])
            }.bind(this) : null);

        // Register to listen for full screen changes.
        var exitHandler = function() {
            this._isFullscreen = document.webkitIsFullScreen;
            this._isFullscreen |= document.mozFullScreen;
            // Not tested on IE.
            this._isFullscreen |= document.msFullscreenElement;
            if (!this._isFullscreen) {
                this._resizeRequired = true;
            } else {
                // The screen changed into fullscreen, nothing to do.
            }
        }.bind(this);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);

        if (!this._fullscreenElement.requestFullScreen) {
            // Shitty apple devices can't handle full screen.
            // We will have to hack it.
            this._fullscreenElement.requestFullScreen = function() {
                this._fullscreenElement.classList.add('fullscreen_hack');
                this._isFullscreen = true;
                this._resizeRequired = true;
            }.bind(this);
        };

        this._fullscreenElement.exitFullScreen = function() {
            // Remove the hack class we have to add on apple devices which can't handle fullscreen.
            this._fullscreenElement.classList.remove('fullscreen_hack');
            this._isFullscreen = false;
            this._resizeRequired = true;
        }.bind(this);
    }

    _enterFullscreen() {
        this._fullscreenElement.requestFullScreen();
        this._isFullscreen = true;
        this._resizeRequired = true;
    }

    _exitFullscreen() {
        if(document.exitFullscreen) {
            document.exitFullscreen();
        } else if(document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if(document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        } else if(document.msExitFullscreen) {
            document.msExitFullscreen();
        } else {
            this._fullscreenElement.exitFullScreen();
        }
        this._isFullscreen = false;
        this._resizeRequired = true;
    }
}

export default VolcapPlayer;