const MeshSourceBuffer = require('./mesh_source_buffer');
const dash = require('@8i/dashjs');

class VolcapDashActor {
    constructor(src, renderer, scene, opts = {}) {
        let {autoplay = false, fps = -1, muted = true, loop = false, abr = true, initialQualityRatio = 0} = opts;
        this._initialized = false;
        this._ready = false;
        this._src = src;
        this._video = document.createElement("video");
        this._video.muted = muted;
        this._video.loop = loop;
        this._fps = fps;
        this._video.onresize = () => {
            const width = this._video.videoWidth;
            const height = this._video.videoHeight;
        }
        this._video.onended = (evt) => {
            if (this.onended) {
                this.onended(evt);
            }
        }
        this._video.onplay = (evt) => {
            if (this.onplay) {
                this.onplay(evt);
            }
        }
        this._video.onpause = (evt) => {
            if (this.onpause) {
                this.onpause(evt);
            }
        }

        this._seeking = false;
        this._video.onseeked = () => {
            this._seeking = false;
            this._lastFrame = -1;
        }

        this._video.onloadeddata = (e) => {
            if (this.onloadeddata) {
                this.onloadeddata(e);
            }
        }

        this._lastFrame = -1;

        this._gl = renderer.getContext();

        const gl = this._gl;
        this._stagingTexture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this._stagingTexture);
        gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, false);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 2048, 2048, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.bindTexture(gl.TEXTURE_2D, null);

        this._framebuffer = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this._framebuffer);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this._stagingTexture, 0);
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);

        this._renderTexture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this._renderTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 2048, 2048, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.bindTexture(gl.TEXTURE_2D, null);

        // This represents the latest texture that we want to render
        this._texture = new THREE.Texture();
        this._texture.minFilter = THREE.LinearFilter;
        this._texture.magFilter = THREE.LinearFilter;
        this._texture.format = THREE.RGBFormat;
        this._texture.anisotropy = 10;

        // override the three.js texture with this
        const texProps = renderer.properties.get(this._texture);
        texProps.__webglTexture = this._renderTexture;

        // And here is the material and the mesh, initially lacking geometry
        let material = new THREE.MeshBasicMaterial({map: this._texture});
        material.side = THREE.DoubleSide;
        this._mesh = new THREE.Mesh(undefined, material);
        this._mesh.scale.set(0.01, 0.01, 0.01);
        this._mesh.castShadow = true;
        this._mesh.receiveShadow = false;
        if (scene)
            scene.add(this._mesh);

        const primer_src = 'https://assets.8i.com/fallback.mp4'; // Universal fallback primer
        const mp4_src = this._src.replace('manifest.mpd', `fallback.mp4`);

        this._availablilityStartTime = undefined;

        MeshSourceBuffer.initialize(primer_src, (isFallback) => {
            this._player = dashjs.MediaPlayer().create();
            this._player.initialize(this._video, this._src, autoplay);

            let filterCapabilities = (representation) => {
                if ('framerate' in representation && this._fps !== -1) {
                    return representation.framerate === `${this._fps}/1`;
                }
                return true;
            }
            this._player.registerCustomCapabilitiesFilter(filterCapabilities);

            this._player.on(dashjs.MediaPlayer.events.MANIFEST_LOADED, ({data: manifest}) => {
                // Identify possible framerate values
                let framerates = Array.from(new Set(
                    manifest.Period_asArray[0].AdaptationSet_asArray
                        .find((as) => as.contentType === 'video')
                        .Representation_asArray
                        .map((rep) => rep.framerate)))
                        .filter((fps) => fps !== undefined);
                if (framerates.length) {
                    let lowest = framerates
                            .reduce((acc, mfps) => {
                                let fps_num = mfps;
                                if (typeof mfps === 'string') {
                                    let res = mfps.match(/(?<fps>\d+)\/1/);
                                    if (res.groups && res.groups.fps) fps_num = parseInt(res.groups.fps);
                                }
                                return Math.min(fps_num, acc);
                            }, Number.MAX_VALUE);
                    // This will cover both cases:
                    // 1. automatic FPS
                    // 2. a selected FPS does not exist
                    if (isFallback || framerates.includes(`${this._fps}/1`) === false) this._fps = lowest;
                } else {
                    this._fps = 15; // TODO get framerates into live manifest
                }
            });

            this._player.on(dashjs.MediaPlayer.events.STREAM_INITIALIZED, ({liveStartTime, streamInfo}) => {
                // console.log(this._player.getBitrateInfoListFor('video'));
                this._availablilityStartTime = streamInfo.manifestInfo.availableFrom;
            });

            this._player.on(dashjs.MediaPlayer.events.BUFFER_EMPTY, ({mediaType}) => {
                if (mediaType === 'video' && this.onbufferempty) {
                    this.onbufferempty();
                }
            });

            this._player.on(dashjs.MediaPlayer.events.BUFFER_LOADED, ({mediaType}) => {
                if (mediaType === 'video' && this.onbufferloaded) {
                    this.onbufferloaded();
                }
            });

            this._player.on(dashjs.MediaPlayer.events.CAN_PLAY, () => {
                if (this.oncanplay) {
                    this.oncanplay();
                }
            });
            
            this._player.updateSettings({
                debug: {
                    logLevel: dashjs.Debug.LOG_LEVEL_NONE
                },
                streaming: {
                    bufferPruningInterval: 10,
                    bufferToKeep: 10,
                    bufferTimeAtTopQuality: 10,
                    bufferTimeAtTopQualityLongForm: 10,
                    abr: {
                        bandwidthSafetyFactor: 0.9,
                        useDefaultABRRules: true,
                        useBufferOccupancyABR: true,
                        useDeadTimeLatency: true,
                        limitBitrateByPortal: false,
                        usePixelRatioInLimitBitrateByPortal: false,
                        maxBitrate: { audio: -1, video: -1, mesh: -1 },
                        minBitrate: { audio: -1, video: -1, mesh: -1 },
                        maxRepresentationRatio : { audio: -1, video: 1, mesh: 1},
                        initialRepresentationRatio: { audio: -1, video: initialQualityRatio, mesh: 0 },
                        autoSwitchBitrate: { audio: false, video: abr, mesh: false },
                    },
                    trackSwitchMode: {
                        audio: 'alwaysReplace',
                        video: 'alwaysReplace',
                        mesh: 'alwaysReplace'
                    },
                    retryIntervals: {
                        MPD:                       1000,
                        XLinkExpansion:            500,
                        MediaSegment:              500,
                        InitializationSegment:     500,
                        BitstreamSwitchingSegment: 1000,
                        IndexSegment:              1000,
                        other:                     1000
                    },
                    retryAttempts: {
                        MPD:                        1000,
                        XLinkExpansion:             1,
                        MediaSegment:               2,
                        InitializationSegment:      2,
                        BitstreamSwitchingSegment:  3,
                        IndexSegment:               3,
                        other:                      3
                    },
                }
            });

            this._player.on(dashjs.MediaPlayer.events.QUALITY_CHANGE_REQUESTED, (e) => {
                // console.log('quality change requested', e);
            });
            this._player.on(dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED, (e) => {
                // console.log('quality change rendered', e);
            });

            
            this._video.onloadedmetadata = (e) => {
                if (!this._initialized) {
                    if (isFallback) {
                        console.log('onloadedmetadata', 'fallback');
                        this._video.crossOrigin = "anonymous";
                        // without the following, iPhone will refuse to autoplay
                        this._video.setAttribute('playsinline', 'playsinline');
                        let hls_src = this._src.replace('manifest.mpd', `master_${this._fps}.m3u8`);
                        fetch(hls_src)
                        .then((res) => {
                            if (res.ok && this._video.canPlayType('application/vnd.apple.mpegURL') !== '') {
                                console.log('HLS is available and playable');
                                this._video.src = hls_src;
                            } else {
                                console.error('No HLS available, falling back to MP4');
                                this._video.src = mp4_src;
                            }
                            this._video.load();
                            if (autoplay) {
                                this._video.play()
                                    .then(console.log('playing fallback'))
                                    .catch(e => console.error('failed to play fallback', e))
                            }
                        });
                    }
                    this._initialized = true;
                }
            }
            this._video.onprogress = (e) => {
                if (e.target.buffered.length == 0) {
                    this._installTimeShim(this._availablilityStartTime);
                }
            }
            this._video.onstalled = (e) => {
                console.error('stalled', e);
            }
            this._video.onsuspend = (e) => {
                console.error('suspend', e);
            }
            this._video.onerror = (e) => {
                console.error('error', e);
            }
            this._video.onabort = (e) => {
                console.error('abort', e);
            }

            this._ready = true;
        }, (sourceBuffer) => {
            this._meshSourceBuffer = sourceBuffer;
        });
    }

    destroy(scene) {
        if (scene)
            scene.remove(this._mesh);
        MeshSourceBuffer.destroy();
        this._meshSourceBuffer = undefined;
        this._video.pause();
        this._video = null;
        this._player.destroy();
        this._player = null;
    }

    play() {
        this._video.play().catch((e) => console.log(e));
    }

    pause() {
        this._video.pause();
    }

    seek(time) {
        this._seeking = true;
        this._video.currentTime = time;
    }

    set muted(muted) {
        this._video.muted = muted;
    }

    get muted() {
        return this._video.muted;
    }

    set loop(loop) {
        this._video.loop = loop;
    }

    get currentTime() {
        return this._video.currentTime;
    }

    get duration() {
        return this._video.duration;
    }

    get paused() {
        return this._video.paused;
    }

    get ready() {
        return this._ready;
    }

    _installTimeShim(AST) {
        if (this._timeShimmed) return;
        // Because HLS uses a relative timeline instead of absolute, we need to
        // override the 'currentTime' method to align it with the MPD timeline
        let offset = 0;//this._getTimeOffset();
        if (typeof this._video.getStartDate === 'function') {
            // This is the HLS "start time" - which is a wall clock value that
            // corresponds to the HLS 'currentTime == 0' value.
            const startDate = this._video.getStartDate();
            console.log(`MPD start time: ${AST} HLS start time: ${startDate}`)
            const startTime = startDate.getTime();
            // This is the MPD AvailabilityStarTime value, which is the actual
            // MPD 'currentTime == 0' wallclock time.
            const astTime = AST.getTime();
            if (!isNaN(startTime)) {
                const diff = startTime - astTime;
                console.log(startTime, astTime, diff)
                offset = diff / 1000;
            } else {
                return;
            }
        }
        if (offset === 0) return;
        console.log(`Using time offset: ${offset}`);
        let oldTimeGetter = this._video.__lookupGetter__('currentTime');
        let oldTimeSetter = this._video.__lookupSetter__('currentTime');
        let video = this._video;
        Object.defineProperty(video, 'currentTime', {
            get: function () {
                const currentTime = oldTimeGetter.call(video);
                return currentTime + offset;
            },
            set: function(value) {
                const offset = getTimeOffset();
                let newTime = value - offset;
                oldTimeSetter.call(video, newTime);
            }
        });
        this._timeShimmed = true;
    }

    _getFrameDetails() {
        // Size of each pixel
        const pixelStride = 4;
        const digitWidth = 16;
        const digitHeight = 1;
        const maxWidth = 2048;
        const height = digitHeight;
        const x = 0;
        const y = 0;
        const data = new Uint8Array(maxWidth * height * 4);
        const gl = this._gl;
        gl.bindFramebuffer(gl.FRAMEBUFFER, this._framebuffer);
        if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) == gl.FRAMEBUFFER_COMPLETE) {
            gl.readPixels(x, y, maxWidth, height, gl.RGBA, gl.UNSIGNED_BYTE, data);
        }
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);

        // First we need to determine how wide this image is.  Possible values are:
        // 1. 512px
        // 2. 1024px
        // 3. 2048px (max)
        // We do this because the HTMLVideoElement videoWidth and videoHeight
        // properties are inaccurate for a few frames when a resolution change
        // occurs.  This is very painful and makes me cry.
        //
        // The alpha values outside of the image bounds will be 0, so we can
        // simply test a few pixels at the edge of possible image widths to
        // determine what the actual width is.
        let testPixel = (idx) => {
            return data[idx * pixelStride + 3] === 255;
        }

        let width = 2048;
        if (testPixel(2047)) {
        } else if (testPixel(1023)) {
            width = 1024;
        } else if (testPixel(511)) {
            width = 512;
        } else {
            return {width: -1, frameNum: -1};
        }

        // Start from the right side, which is the least-significant binary
        // value, and move to the left 16px at a time to decode the number.
        const digitStride = digitWidth * pixelStride;
        const start = (width * pixelStride) - (digitStride / 2);
        let frameNum = -1;
        let totalSum = 0;
        let digits = [];
        for (let j = 0; j < 10; j++) {
            let idx = start - j * digitStride;
            let r = data[idx];
            let g = data[idx + 1];
            let b = data[idx + 2];
            let sum = r + g + b;
            digits.push(sum);
            if (sum > 700) {
                if (frameNum == -1) frameNum = 0;
                frameNum += 1 << j;
            }
            totalSum += sum;
        }
        // If we didn't positively identify a value based on white boxes, we
        // should look at the sum of all the boxes to see if it's mostly black
        // and then probably a zero.
        if (frameNum === -1 && totalSum < 30) frameNum = 0;
        return {width, frameNum};
    }

    update(elapsed, _currentTime, _duration) {

        if (this._seeking) return;

        if (!this._meshSourceBuffer) return;

        if (this._video.videoHeight == 0) return;

        if (this._video.readyState <= this._video.HAVE_METADATA) return;

        let currentTime = this._video.currentTime;
        let duration = this._video.duration;

        const gl = this._gl;
        gl.bindTexture(gl.TEXTURE_2D, this._stagingTexture);
        gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, duration === Infinity);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, this._video);
        gl.bindTexture(gl.TEXTURE_2D, null);

        const {width, frameNum} = this._getFrameDetails();

        // Re-bind render texture
        gl.bindTexture(gl.TEXTURE_2D, this._renderTexture);

        // Live and VOD have slightly different sync strategies.
        if (duration === Infinity) {
            if (frameNum > -1) {
                if (frameNum != this._lastFrame) {
                    let geometry = this._meshSourceBuffer.getMeshByFrameId(frameNum);
                    if (geometry) {
                        this._mesh.geometry.dispose();
                        this._mesh.geometry = geometry;
                        const height = width;
                        const gl = this._gl;
                        gl.bindFramebuffer(gl.FRAMEBUFFER, this._framebuffer);
                        gl.copyTexImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 0, 0, width, height, 0);
                        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
                        this._lastFrame = frameNum;
                    } else {
                        console.warn(`No mesh for ${frameNum}`);
                    }
                } else if (frameNum == this._lastFrame) {
                    // Skip
                }
            }
        } else {
            const frameDuration = this._meshSourceBuffer.getFrameDuration();
            const timescale = 90000; // This should be pretty constant.
            if (frameDuration && frameNum > -1) {
                let scaledTime = Math.round(currentTime * timescale);
                let alignedTime = Math.floor(scaledTime / frameDuration) * frameDuration;
                // Now we are going to try to adjust the "estimated" frame number
                // based on the frame number we pulled from the staging frame.
                let estimatedIdx = alignedTime / frameDuration;
                let bucket = Math.floor(estimatedIdx / 1024.0) * 1024;
                let correctedIdx = bucket + frameNum;

                if (correctedIdx > this._lastFrame || this._lastFrame == 1023) {
                    let correctedTime = correctedIdx * frameDuration;
                    let geometry = this._meshSourceBuffer.getMesh(correctedTime);
                    if (geometry) {
                        this._mesh.geometry.dispose();
                        this._mesh.geometry = geometry;
                        const height = (w) => {
                            switch(w) {
                                case 2048: return 2080;
                                case 1024: return 1040;
                                case 512: return 520;
                            }
                        };
                        const gl = this._gl;
                        gl.bindFramebuffer(gl.FRAMEBUFFER, this._framebuffer);
                        gl.copyTexImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 0, 0, width, height(width), 0);
                        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
                        this._lastFrame = correctedIdx;
                    } else {
                        console.warn(`No mesh for ${correctedTime}`);
                    }
                } else if (correctedIdx == this._lastFrame) {
                    // Skip
                }
            }

        }
    }

    render (width, height, camera, position, hidden) {
        this._mesh.visible = !hidden;
        this._mesh.position.copy(position);
    }
}

module.exports = VolcapDashActor;
