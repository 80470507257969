const helpers = require('../helpers');
const VolcapDashActor = require('./dash');
const VolcapHVRActor = require('./hvr');

if (window.AFRAME) {
    window.AFRAME.registerComponent('hologram', {
        schema: {
            abr: {type: 'boolean', default: true},
            initialQualityRatio: {type: 'number', default: 0},
            src: {type: 'string', default: ""},
            autoplay: {type: 'boolean', default: true},
            loop: {type: 'boolean', default: false},
            muted: {type: 'boolean', default: true},
            fps: {type: 'number', default: -1}
        },
        init: function () {
            let data = this.data;
            let el = this.el;
            let src = data.src;
            // let scene = this.el.sceneEl.object3D;  // THREE.Scene
            let renderer = this.el.sceneEl.renderer;
            this._impl = new VolcapDashActor(src, renderer, null, data);
            this._impl.onplay = (evt) => {
                this.el.emit('onplay', evt);
            }
            this._impl.onpause = (evt) => {
                this.el.emit('onpause', evt);
            }
            this._impl.onended = (evt) => {
                this.el.emit('onended', evt);
            }
            this._impl.onbufferempty = () => {
                this.el.emit('onbufferempty');
            }
            this._impl.onbufferloaded = () => {
                this.el.emit('onbufferloaded');
            }
            this._impl.oncanplay = (evt = {}) => {
                this.el.emit('oncanplay', evt);
            }
            el.setObject3D('mesh', this._impl._mesh);
            this._ready = false;

            Object.defineProperty(this, 'paused', {
                get() {
                    return this._impl.paused;
                }
            });

            Object.defineProperty(this, 'currentTime', {
                get() {
                    return this._impl.currentTime;
                }
            });

            // This is normally provided by A-Frame itself, but we need to
            // override it to represent the actual video playback state so we
            // can use the play() method.
            Object.defineProperty(this, 'isPlaying', {
                get() {
                    return !this._impl.paused;
                }
            });
        },
        tick: function (time, timeDelta) {
            if (this._impl) {
                this._impl.update(time);
            }
            // This is CRITICAL for WebXR mode - we need to re-bind the WebXR
            // framebuffer or nothing will be rendered to the XR display.
            const gl = this.el.sceneEl.renderer.getContext();
            if (this.el.sceneEl.frame) {
                const frame = this.el.sceneEl.frame;
                let origFB = frame ? frame.session.renderState.baseLayer.framebuffer : null;
                gl.bindFramebuffer(gl.FRAMEBUFFER, origFB);
            }
        },
        update: function (prev) {
            let {data, el} = this;

            if (prev.src && prev.src != data.src) {
                console.error('Changing source not supported. Please destroy and re-create hologram');
            }

            if (prev.fps && prev.fps != data.fps) {
                console.error('Changing fps not supported. Please destroy and re-create hologram');
            }

            this._impl.muted = data.muted;
            this._impl.autoplay = data.autoplay;
            this._impl.loop = data.loop;
        },
        remove: function () {
            this._impl.destroy();
            this._impl = null;
            this.el.removeObject3D('mesh');
        },
        seek: function (time) {
            if (this._impl.seek) {
                this._impl.seek(time)
            } else {
                console.error('Seek not supported.');
            }
        },
        pause: function () {
            this._impl.pause()
        },
        play: function () {
            // skip the first 'play', which occurs after the
            // entity is added to the scene. The 'autoplay'
            // property should be used to control playback start
            if (this._ready) {
                this._impl.play();
            }
            this._ready = true;
        }
    });
}

class VolcapActor extends HTMLElement {
    constructor() {
        super();

        this._src = null;

        this._asset = null;
        this._onInitialised = null;
        this._onUpdate = null;

        this._audioSrc = null;

        this._arEnabled = false;
    }
    connectedCallback() {
        if(this.hasAttribute('src')) {
            this._src = this.getAttribute('src');
        }
        if(this.hasAttribute('oninit')) {
            this._onInitialised = this.getAttribute('oninit');
        }
        if(this.hasAttribute('onupdate')) {
            this._onUpdate = this.getAttribute('onupdate');
        }
        if(this.hasAttribute('audio')) {
            this._audioSrc = this.getAttribute('audio');
        }
    }

    disconnectedCallback() {
        this._impl.destroy(this._scene);
        this._impl = null;
    }

    get src() { return this._src; }
    set src(newSrc) { this._src = newSrc; }

    // Public API
    play() {
        console.log('play');
        this._impl.play();
    }

    pause() {
        this._impl.pause();
    }

    seek (time) {
        this._impl.seek(time);
    }

    set muted(muted) {
        this._impl.muted = muted;
    }

    get muted() {
        return this._impl.muted;
    }

    get currentTime() {
        return this._impl.currentTime;
    }

    get duration() {
        return this._impl.duration;
    }

    set loop(loop) {
        this._impl.loop = loop;
    }

    get paused() {
        return this._impl.paused;
    }

    get ready() {
        if (this._impl)
            return this._impl.ready;
        else
            return false;
    }

    initialise(scene, renderer, opts = {}) {
        this._scene = scene;
        if(!this._src) {
            console.warn('No "src" specified for eighti actor');
            return;
        }

        // Load the format-specific player infrastructure
        if (this._src.indexOf('mpd') !== -1) {
            this._impl = new VolcapDashActor(this._src, renderer, scene, opts);
            this._arEnabled = true;
        } else {
            this._impl = new VolcapHVRActor(this._src, this._audioSrc, renderer, scene, opts);
        }

        this._impl.onended = (evt) => {
            if (this.onended) {
                this.onended(evt);
            }
        }
        this._impl.onplay = (evt) => {
            if (this.onplay) {
                this.onplay(evt);
            }
        }
        this._impl.onpause = (evt) => {
            if (this.onpause) {
                this.onpause(evt);
            }
        }
        this._impl.onloadeddata = (evt) => {
            if (this.onloadeddata) {
                this.onloadeddata(evt);
            }
        }
    }

    destroy () {
        this._impl.destroy(this._scene);
        this._impl = null;
    }

    get arEnabled() {
        return this._arEnabled;
    }

    render(width, height, camera, position, hidden) {
        if ('render' in this._impl)
            this._impl.render(width, height, camera, position, hidden);
    }

    update(elapsed, currentTime, duration) {
        this._impl.update(elapsed, currentTime, duration);

        this._callOnInit();
        if(this._onUpdate === null) {
            return;
        } else if(typeof this._onUpdate == 'function') {
            this._onUpdate(this, currentTime, duration);
        } else if(this._onUpdate in window) {
            const func = window[this._onUpdate];
            if(typeof func === 'function') {
                this._onUpdate = func;
                this._onUpdate(this, currentTime, duration);
            } else {
                console.error('"onupdate" is not a function')
                this._onUpdate = null;
            }
        }
    }

    _callOnInit() {
        if(this._onInitialised && this._onInitialise in window) {
            const func = window[this._onInitialised];
            if(typeof func === 'function') {
                func(this);
            } else {
                console.error('"oninit" is not a function');
            }
            this._onInitialise = null;
        }
    }
}

module.exports = VolcapActor;