if (window.AFRAME) {
    console.log('register A-Frame helpers');
    AFRAME.registerComponent('eighti-ar-hide', {
        init: function () {
            this.el.sceneEl.addEventListener('enter-vr', (ev) => {
                if (this.el.sceneEl.is('ar-mode')) {
                    this.el.setAttribute('visible', false);
                }
            });
            this.el.sceneEl.addEventListener('exit-vr', (ev) => {
                this.el.setAttribute('visible', true);
            });
        }
    });

    AFRAME.registerComponent('eighti-ar-hit-test', {
        schema: {
            target: {type: 'string', default: ""},
        },
        init: function () {
            this.xrHitTestSource = null;
            this.viewerSpace = null;
            this.refSpace = null;

            this.el.sceneEl.renderer.xr.addEventListener('sessionend', (ev) => {
                this.viewerSpace = null;
                this.refSpace = null;
                this.xrHitTestSource = null;
                let position = this.initialPosition || '0 0 0';
                this.target.setAttribute('position', position);
                this.el.setAttribute('visible', false);
            });
            this.el.sceneEl.renderer.xr.addEventListener('sessionstart', (ev) => {
                let session = this.el.sceneEl.renderer.xr.getSession();
                let data = this.data;
                this.target = document.querySelector(data.target);
                this.initialPosition = this.target.getAttribute('position');

                let element = this.el;
                session.addEventListener('select', () => {
                    let position = element.getAttribute('position');
                    if (this.target) {
                        this.target.setAttribute('position', position);
                        this.target.setAttribute('visible', true);
                    }
                });

                session.requestReferenceSpace('viewer').then((space) => {
                    this.viewerSpace = space;
                    session.requestHitTestSource({ space: this.viewerSpace })
                        .then((hitTestSource) => {
                            this.xrHitTestSource = hitTestSource;
                        })
                        .catch((e) => {
                            console.error('no hit-test support');
                            this.target.setAttribute('visible', true);
                        });
                });
                session.requestReferenceSpace('local').then((space) => {
                    this.refSpace = space;
                });
            });
        },
        tick: function () {
            if (this.el.sceneEl.is('ar-mode')) {
                if (!this.viewerSpace) return;

                let frame = this.el.sceneEl.frame;
                let xrViewerPose = frame.getViewerPose(this.refSpace);

                if (this.xrHitTestSource && xrViewerPose) {
                    let hitTestResults = frame.getHitTestResults(this.xrHitTestSource);
                    if (hitTestResults.length > 0) {
                        this.el.setAttribute('visible', true);
                        let pose = hitTestResults[0].getPose(this.refSpace);

                        let inputMat = new THREE.Matrix4();
                        inputMat.fromArray(pose.transform.matrix);

                        let position = new THREE.Vector3();
                        position.setFromMatrixPosition(inputMat);
                        this.el.setAttribute('position', position);
                        return;
                    }
                }
                this.el.setAttribute('visible', false);
            }
        }
    });
}
