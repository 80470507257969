(function(){
    const helpers = require('./helpers');
    require('./aframe-helpers');
    const VolcapPlayer = require('./player').default;
    const VolcapActor = require('./actor');
    
    var supportsCustomElements = ('customElements' in window);
    var supportsShadowDOM = ('attachShadow' in document.head);
    if (supportsCustomElements && supportsShadowDOM) {
        customElements.define('volcap-player', VolcapPlayer);
        customElements.define('volcap-actor', VolcapActor);
    } else {
        if(!supportsCustomElements) {
            console.log('Browser does not support Custom Elements!');
        } else if(!supportsShadowDOM) {
            console.log('Browser does not support Shadow DOM!');
        }
        document.addEventListener("DOMContentLoaded", function() {
            helpers.displayLoadingError();
        });
    }
}());
