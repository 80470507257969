class ARControls {
    constructor (domElement) {
        this.moved = false;
        this.object = null;
        this.domElement = domElement;
        this._attach();
        this._enabled = false;
    }

    set enabled(state) {
        this._enabled = state;
        if (this._enabled === false) {
            // Clear any rotation now
            this.object.setRotationFromEuler(new THREE.Euler(0, 0, 0));
        }
    }

    get enabled() {
        return this._enabled;
    }

    attach(object) {
        this.object = object;
    }

    _getPointer(evt) {
        const pointer = evt.changedTouches ? evt.changedTouches[ 0 ] : evt;
        const rect = this.domElement.getBoundingClientRect();
        return {
            x: ( pointer.clientX - rect.left ) / rect.width * 2 - 1,
            y: - ( pointer.clientY - rect.top ) / rect.height * 2 + 1,
        };
    }

    _onTouchDown(evt) {
        if (!this.enabled || !this.object) return;
        const ptr = this._getPointer(evt);
        this.lastPtr = ptr;
    }

    _onTouchMove(evt) {
        if (!this.enabled || !this.object) return;
        this.moved = true;
        const ptr = this._getPointer(evt);
        const delta = ptr.x - this.lastPtr.x;
        this.lastPtr = ptr;
        this.object.rotateY(delta);
    }

    _onTouchUp(evt) {
        if (!this.enabled || !this.object) return;
        this.lastPtr = null;
        if (this.moved === false) {
            this.domElement.dispatchEvent(new Event('select'));
        }
        this.moved = false;
    }

    _attach() {
        this.domElement.addEventListener( "touchstart", this._onTouchDown.bind(this) );
        this.domElement.addEventListener( "touchmove", this._onTouchMove.bind(this) );
        this.domElement.addEventListener( "touchend", this._onTouchUp.bind(this) );
        this.domElement.addEventListener( "touchcancel", this._onTouchUp.bind(this) );
        this.domElement.addEventListener( "touchleave", this._onTouchUp.bind(this) );
    }

    _detach() {
        this.domElement.removeEventListener( "touchstart", this._onTouchDown.bind(this) );
        this.domElement.removeEventListener( "touchmove", this._onTouchMove.bind(this) );
        this.domElement.removeEventListener( "touchend", this._onTouchUp.bind(this) );
        this.domElement.removeEventListener( "touchcancel", this._onTouchUp.bind(this) );
        this.domElement.removeEventListener( "touchleave", this._onTouchUp.bind(this) );
    }
}

module.exports = ARControls;
